const Device = (function () {

  this.chart = null;
  this.datepicker = null;
  this.deviceId = null;
  this.dataNodes = null;
  this.translations = null;

  this.init = (chartId, datepicker, dataNodes, deviceId, translations) => {
    this.datepicker = datepicker;
    this.dataNodes = dataNodes;
    this.deviceId = deviceId;
    this.translations = translations;
    this.chartId = chartId;

    $(document).ready(() => {
      this.initializeDatePicker();

      const dataNodesWithoutValues = this.dataNodes.map(dataNode => ({...dataNode, dataNodeValues: []}));
      this.chart = IOT.MixedMultiAxisChartFactory.create(this.chartId, dataNodesWithoutValues, moment().format(), this.translations);

      this.fetchValuesForDate(new Date());
    });
  };

  this.initializeDatePicker = () => {
    this.datepicker.datetimepicker({format: IOT.MixedMultiAxisChartFactory.displayDateFormat});
    this.datepicker.data('DateTimePicker').options({date: new Date()});
    this.registerDatePickerEventListener();
  };

  this.registerDatePickerEventListener = () => {
    this.datepicker.on('dp.change', (e) => {
      this.fetchValuesForDate(e.date);
    });
  };

  this.fetchValuesForDate = (date) => {
    const dateRange = this.updateChartDateRange(date);
    Device.fetchMultipleDataNodeValuesForDateRange(dateRange, this.dataNodes, this.deviceId);
  };

  this.fetchMultipleDataNodeValuesForDateRange = (dateRange, dataNodes, deviceId) => {
    const deviceUrl = `/api/devices/${deviceId}`;

    return Promise.all(dataNodes.map(dataNode => {
      return this.fetchDataNodeValuesForDateRange('/user/gateway?uri=', deviceUrl, dateRange, dataNode)
        .then(dataNode => {
          return this.updateDataset(dataNode);
        });
    }));
  };

  this.fetchDataNodeValuesForDateRange = (baseUrl, deviceUrl, dateRange, dataNode, limit) => {
    if (!limit) {
      limit = 24 * 60 * 60;
    }
    const valuesUrl = `${deviceUrl}/datanodes/${dataNode.id}/values?startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&limit=${limit}`;
    const url = baseUrl + encodeURIComponent(valuesUrl);
    return $.getJSON(url).then(response => {
      return {
        deviceId,
        id: dataNode.id,
        dataNodeValues: response.values
      }
    });
  };

  this.updateChartDateRange = (dateString) => {
    const timeOptions = this.chart.options.scales.xAxes[0].time;
    timeOptions.min = moment(dateString, IOT.MixedMultiAxisChartFactory.displayDateFormat).startOf('day');
    timeOptions.max = moment(dateString, IOT.MixedMultiAxisChartFactory.displayDateFormat).endOf('day');

    return dateRange = {
      startDate: timeOptions.min.format(),
      endDate: timeOptions.max.format()
    };
  };

  this.updateDataset = (dataNode) => {
    logger.log('updateDataset', dataNode);
    const dataset = this.chart.data.datasets.find(d => d.dataNodeId === dataNode.id);
    dataset.data = IOT.MixedMultiAxisChartFactory.transformDataNodeValues(reduceDataset(dataNode.dataNodeValues.reverse()));
    chart.update();
    return dataNode;
  };

  return this;
})();

global['Device'] = Device;
