const isLocal = (window.location.host.indexOf("localhost") >= 0 || window.enableLogger);

const log = (...args) => {
  if (isLocal) {
    console.log(...args);
  }
};

const warn = (...args) => {
  if (isLocal) {
    console.warn(...args);
  }
};

export const logger = {log, warn};
