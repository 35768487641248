// import * as $ from 'jquery';
const $ = require('jquery');
// global fix: "Uncaught ReferenceError: $ is not defined"
global['$'] = global['jQuery'] = $;

require('bootstrap');
require('eonasdan-bootstrap-datetimepicker');
import moment from 'moment';
global['moment'] = moment;
import Chart from 'chart.js';
import toastr from 'toastr';
global['toastr'] = toastr;
import Vue from 'vue/dist/vue';
global['Vue'] = Vue;

require('./config');
require('./dataNode');
import {reduceDataset} from './dataSetUtils';
global['reduceDataset'] = reduceDataset;
require('./datepicker');
require('./main');
require('./device');
import {logger} from './logger';
global['logger'] = logger;
require('./plugins');

import 'toastr/build/toastr.css'
import '../css/themes/cosmo.min.css'
import '../css/custom_cosmo.css'
import '../css/datepicker.css'
import '../css/main.css'
