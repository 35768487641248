import {logger} from './logger';

export const reduceDataset = (dataNodeValues, threshold = 24 * 60, desiredInterval = 15) => {
  logger.log('reduceDataset before:', dataNodeValues.length);

  if (dataNodeValues.length < threshold / desiredInterval) {
    logger.log(`reduceDataset, no reduce needed because threshold of ${threshold / desiredInterval} not reached`);
    return dataNodeValues;
  }

  const reducedDataset = [];
  const allValues = dataNodeValues.map(v => v.value);
  const maxValue = Math.max(...allValues);
  const minValue = Math.min(...allValues);
  const valueRange = Math.abs(maxValue - minValue);

  for (let index = 0; index < dataNodeValues.length; index++) {
    const value = dataNodeValues[index];

    if (reducedDataset.length === 0) {
      reducedDataset.push(value);
      continue;
    }

    const lastValue = reducedDataset[reducedDataset.length - 1];
    const timestampDifference = moment.duration(moment(lastValue.timestamp).diff(moment(value.timestamp)));

    const deviation = 100 / (valueRange / Math.abs(lastValue.value - value.value));
    if (deviation > 2.5 || Math.abs(timestampDifference.asMinutes()) >= desiredInterval) {
      reducedDataset.push(value);
    }
  }
  logger.log('reduceDataset after:', reducedDataset.length);
  return reducedDataset;
};
