$(document).ready(() => {
  if (document.getElementById('app')) {
    const dataNode = initDataNode();
  }
});

const initDataNode = () => {
  return new Vue({
    el: '#app',
    data: {
      activeRequest: false,
      dateFormat: 'DD.MM.Y HH:mm:ss',
      apiGatewayUri: '/user/gateway?uri=',
      $startDate: null,
      startDate: null,
      $endDate: null,
      endDate: null,
      values: [],
      labels: [],
      unit: '',
      currentValue: '',
      tableViewElements: [],
      maxDataPoints: 5000,
      sort: 'asc',
      $reloadButton: null,
      $currentValue: null,
      liveReload: false,
      reloadInterval: null,
      reloadIntervalTime: 1500,
      buttonInactive: 'btn-primary',
      buttonActive: 'btn-danger',
      isColorized: false
    },
    methods: {
      reloadData(startDate, endDate, showNoValuesWarning = true) {
        if (!startDate) {
          startDate = this.startDate;
        }

        logger.log('reloadData', {startDate, endDate});
        const vue = this;
        if (this.activeRequest) {
          return;
        }
        vue.activeRequest = true;
        $.getJSON(this.generateUrl(startDate, endDate)).then((response) => {
          vue.activeRequest = false;
          vue.parseResponse(response, showNoValuesWarning);
          if ((dataNodeType === 'bool' || dataNodeType === 'number') && isKeepHistory) {
            vue.updateBigChart();
          }
          if (dataNodeType === 'text') {
            vue.updateTableData();
          }
          if (vue.liveReload) {
            vue.updateDatePickerValuesBasedOnData(false, true);
          }
          if (response.values.length >= this.maxDataPoints) {
            vue.showMaxExceededWarning();
          }
        });
      },
      showNoValuesWarning() {
        let msg = $('#error_no_values').val();
        msg = msg.replace('%startDate%', this.startDate);
        msg = msg.replace('%endDate%', this.endDate);
        toastr.warning(msg);
      },
      showMaxExceededWarning() {
        let msg = $('#error_values_max_exceeded').val();
        toastr.warning(msg);
      },
      parseResponse(response, showNoValuesWarning) {
        logger.log('parseResponse', {count: response.values.length, showNoValuesWarning});
        let vue = this;
        let label;
        let value;
        let labels = [];
        let values = [];
        if (response.values.length === 0 && showNoValuesWarning) {
          vue.showNoValuesWarning();
        }
        const dataNodeValues = reduceDataset(response.values, 24 * 60, 1);
        dataNodeValues.forEach(function (ele) {
          label = moment(ele.timestamp).format(vue.dateFormat);
          value = ele.value;
          labels.push(label);
          values.push(value);
          if (dataNodeType === 'text') {
            vue.tableViewElements.push({label: label, value: value});
          }
        });

        this.labels = labels;
        this.values = values;

        if (this.liveReload && vue.values.length > 0) {
          let valueText = vue.values[vue.values.length - 1];
          vue.currentValue.html(this.translateBool(valueText) + this.unit);
        }
      },
      translateBool(valueText) {
        if (valueText === true) {
          valueText = $('#value_true').val();
        } else if (valueText === false) {
          valueText = $('#value_false').val();
        }
        return valueText;
      },
      updateBigChart() {
        logger.log('updateBigChart');
        let labels = this.labels;
        let values = this.values;
        bigChart.data.labels = labels;
        bigChart.data.datasets[0].data = values;
        this.setChartPointRadius();
        bigChart.update();
      },
      updateTableData() {
        logger.log('updateTableData');
        let elements = this.tableViewElements;
        let newDom = '';
        for (let i = elements.length - 1; i > 0; i--) {
          let ele = elements[i];
          newDom += `<tr><td class="timestamp">${ele.label}</td><td class="value">${ele.value}</td></tr>`
        }
        $('#datanode-chart').html(`<tbody>${newDom}</tbody>`);
        this.tableViewElements = [];
      },
      generateUrl(startDate, endDate) {
        logger.log('generateUrl', {startDate, endDate});

        let limit = this.maxDataPoints;
        let params = dataNodeUrl + '?limit=' + limit + '&sort=' + this.sort;
        if (startDate) {
          startDate = moment(startDate, this.dateFormat).format();
          params += '&startDate=' + startDate;
        }
        if (endDate) {
          endDate = moment(endDate, this.dateFormat).format();
          params += '&endDate=' + endDate;
        }
        return this.apiGatewayUri + encodeURIComponent(params);
      },
      updateDatePickerValuesBasedOnData(updateStartDate = true, updateEndDate = true) {
        logger.log('updateDatePickerValuesBasedOnData', {updateStartDate, updateEndDate});
        let start;
        let end;

        if (!this.values) {
          return;
        }

        if (bigChart) {
          start = bigChart.data.labels[0];
          end = bigChart.data.labels[bigChart.data.labels.length - 1];
        }
        if (dataNodeType === 'text') {
          let ts = $('.timestamp');
          end = ts.first().html();
          start = ts.last().html();
        }
        if (updateEndDate) {
          this.endDate = moment(end, this.dateFormat).format(this.dateFormat);
        }
        if (updateStartDate) {
          this.startDate = moment(start, this.dateFormat).format(this.dateFormat);
        }
      },
      setInitialDates() {
        logger.log('setInitialDates');
        this.startDate = moment().startOf('day').format(this.dateFormat);
        this.endDate = moment().endOf('day').format(this.dateFormat);
      },
      initDatepickers() {
        logger.log('initDatepickers');
        let vue = this;
        this.$startDate = $('#startDate');
        this.$endDate = $('#endDate');
        this.setInitialDates();
        this.$startDate.datetimepicker({format: this.dateFormat})
          .on('dp.change', (e) => {
            this.startDate = moment(e.date).format(vue.dateFormat);
            this.reloadData(this.startDate, this.endDate);
          });
        this.$endDate.datetimepicker({format: this.dateFormat})
          .on('dp.change', (e) => {
            this.endDate = moment(e.date).format(vue.dateFormat);
            this.reloadData(this.startDate, this.endDate);
          });
      },
      stopLiveReload() {
        logger.log('stopLiveReload');
        $('.glyphicon-pause').addClass('glyphicon-refresh');
        $('.glyphicon-refresh').removeClass('glyphicon-pause');
        this.liveReload = false;
        this.$reloadButton.removeClass(this.buttonActive);
        this.$reloadButton.addClass(this.buttonInactive);
        clearInterval(this.reloadInterval);
        this.reloadInterval = null;
      },
      startLiveReload() {
        logger.log('startLiveReload');
        let vue = this;
        $('.glyphicon-refresh').addClass('glyphicon-pause');
        $('.glyphicon-pause').removeClass('glyphicon-refresh');
        this.liveReload = true;
        this.$reloadButton.removeClass(this.buttonInactive);
        this.$reloadButton.addClass(this.buttonActive);
        this.reloadInterval = setInterval(function () {
          vue.reloadData();
        }, this.reloadIntervalTime);
      },
      colorizeDate() {
        logger.log('colorizeDate');
        let $startPicker = $('#startDate input');
        if (this.isColorized) {
          $startPicker.css('background-color', '#FFF');
        } else {
          $startPicker.css('background-color', 'rgba(149, 187, 216,0.5)');
        }
        this.isColorized = !this.isColorized;
      },
      setChartPointRadius() {
        logger.log('setChartPointRadius');
        if (bigChart) {
          if (bigChart.data.datasets[0].data.length > 100) {
            bigChart.data.datasets[0].pointRadius = 0;
          } else {
            bigChart.data.datasets[0].pointRadius = 3;
          }
        }
      }
    },
    mounted() {
      logger.log('mounted');
      if (isKeepHistory) {
        createCharts();
        this.initDatepickers();
        this.reloadData(this.startDate, this.endDate, false);
      }
      this.unit = ' ' + dataNodeUnit;
      this.currentValue = $('#current-value');
      this.$reloadButton = $('#reload');
      this.$reloadButton.on('click', (e) => {
        if (this.liveReload) {
          this.stopLiveReload();
        } else {
          this.startLiveReload();
        }
      });
    }
  });
};
