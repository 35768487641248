const IOT = (function () {

  function ChartFactory() {

    this.defaultBgColor = 'rgba(51, 122, 183, 0.4)';
    this.defaultBorderColor = 'rgba(51, 122, 183, 1)';

    this.bgColors = [
      'rgba(255, 99, 132, 0.4)',
      'rgba(54, 162, 235, 0.4)',
      'rgba(255, 206, 86, 0.4)',
      'rgba(75, 192, 192, 0.4)',
      'rgba(153, 102, 255, 0.4)',
      'rgba(255, 159, 64, 0.4)'
    ];
    this.borderColors = [
      'rgba(255, 99, 132, 1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)'
    ];

    this.createDataSet = function (data, label, backgroundColor = this.defaultBgColor, borderColor = this.defaultBorderColor, fill = true, customOptions = {}) {
      return Object.assign({
        label: label,
        data: data,
        borderWidth: 1,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        fill: fill
      }, customOptions);
    };

    this.create = function (canvasId, datasets, yAxisLabels, dataUnit, chartType = 'line', customOptions = {}) {
      let chart_element = document.getElementById(canvasId);
      return new Chart(chart_element, {
        type: chartType,
        data: {
          labels: yAxisLabels,
          datasets: datasets
        },
        options: Object.assign(this.makeChartOptions(dataUnit), customOptions)
      });
    };

    this.makeChartOptions = function (unit) {
      return {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                return `${value} ${unit}`;
              }
            }
          }]
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              return `${data.datasets[tooltipItems.datasetIndex].label}: ${tooltipItems.yLabel} ${unit}`;
            }
          }
        }
      }
    };
  };

  function MiniChartFactory() {

    ChartFactory.apply(this);

    this.create = function (canvasId, datasets, labels, chartType = 'line', customOptions = {}) {
      let chartelement = document.getElementById(canvasId);
      return new Chart(chartelement, {
        type: chartType,
        data: {
          labels: labels,
          datasets: datasets
        },
        options: Object.assign({
          legend: {
            display: false
          },
          title: {
            display: false
          },
          scales: {
            xAxes: [{
              display: false
            }],
            yAxes: [{
              display: false
            }],
          },
          tooltips: {
            enabled: false
          },
          responsive: true,
          maintainAspectRatio: false
        }, customOptions)
      });
    };

    this.createDataSet = function (data, label, backgroundColor = this.defaultBgColor, borderColor = this.defaultBorderColor, customOptions = {}) {
      return Object.assign({
        label: label,
        data: data,
        borderWidth: 1,
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        pointRadius: 0
      }, customOptions);
    };
  }

  function MixedMultiAxisChartFactory() {

    ChartFactory.apply(this);

    this.config = {
      type: 'line',
      data: {
        datasets: []
      },
      options: {
        responsive: true,
        elements: {point: {radius: 2}},
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
          mode: 'point'
        },
        responsiveAnimationDuration: 0,
        legend: {
          position: 'top'
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                unit: 'minute',
                displayFormats: {
                  minute: 'HH:mm'
                },
                tooltipFormat: 'll HH:mm'
              },
              scaleLabel: {
                display: true
              }
            }
          ],
          yAxes: []
        },
      }
    };

    this.displayDateFormat = 'DD.MM.Y';

    this.getUnitStepSizeForWidth = function (width) {
      if (width > 850) {
        return 30;
      } else if (width <= 850 && width > 450) {
        return 60;
      } else if (width <= 450) {
        return 120;
      }
    };

    this.create = function (canvasId, dataNodes, timestamp, translations) {
      const chartConfig = $.extend(true, {}, this.config);

      chartConfig.options.scales.xAxes[0].time.unitStepSize = this.getUnitStepSizeForWidth($(`#${canvasId}`).parent().width());
      chartConfig.options.onResize = (chart, dimensions) => { chart.options.scales.xAxes[0].time.unitStepSize = this.getUnitStepSizeForWidth(dimensions.width) };

      let units = [];
      const axisIdPrefix = 'axis';

      if (!timestamp) {
        timestamp = moment().format();
      }
      chartConfig.options.scales.xAxes[0].time.min = moment(timestamp).startOf('day');
      chartConfig.options.scales.xAxes[0].time.max = moment(timestamp).endOf('day');

      for (let dataNodeIndex = 0; dataNodeIndex < dataNodes.length; dataNodeIndex++) {
        const dataNode = dataNodes[dataNodeIndex];
        this.transformDataNodeUnit(dataNode);

        let axisId;
        if (units.indexOf(dataNode.unit) === -1) {
          units.push(dataNode.unit);
          axisId = `${axisIdPrefix}${units.indexOf(dataNode.unit)}`;

          const yAxis = {
            id: axisId,
            scaleLabel: {
              display: true
            },
            ticks: {
              beginAtZero: true,
              callback: function (value, index, values) {
                if (dataNode.valueType === 'boolean') {
                  return translations.boolean[!!value];
                }
                return `${value} ${dataNode.unit}`;
              }
            },
            beforeFit: this.hideAxisIfNoDatasetVisible
          };
          if (dataNode.valueType === 'boolean') {
            yAxis.ticks.stepSize = 1;
          }

          chartConfig.options.scales.yAxes.push(yAxis);
        } else {
          axisId = `${axisIdPrefix}${units.indexOf(dataNode.unit)}`;
        }

        const datasetLabel = this.getDataNodeLabel(dataNode);

        let dataset = {
          dataNodeId: dataNode.id,
          label: datasetLabel,
          yAxisID: axisId,
          borderWidth: 1,
          backgroundColor: this.bgColors[dataNodeIndex],
          borderColor: this.borderColors[dataNodeIndex],
          fill: false,
          data: this.transformDataNodeValues(dataNode.dataNodeValues.reverse())
        };
        if (dataNode.valueType === 'boolean') {
          dataset.steppedLine = true;
        }
        chartConfig.data.datasets.push(dataset);
      }

      chartConfig.options.tooltips = {
        callbacks: {
          label: function (tooltipItems, data) {
            const valueType = dataNodes[tooltipItems.datasetIndex].valueType;
            let valueLabel = '';
            if (valueType === 'boolean') {
              valueLabel = translations.boolean[!!tooltipItems.yLabel];
            } else {
              const yAxisID = data.datasets[tooltipItems.datasetIndex].yAxisID;
              const yAxis = chartConfig.options.scales.yAxes.find(a => a.id === yAxisID);
              const yAxisIndex = chartConfig.options.scales.yAxes.indexOf(yAxis);
              valueLabel = tooltipItems.yLabel + ' ' + units[yAxisIndex];
            }

            return data.datasets[tooltipItems.datasetIndex].label + ': ' + valueLabel;
          }
        }
      };

      return new Chart(document.getElementById(canvasId), chartConfig);
    };

    this.getDataNodeLabel = function (dataNode) {
      if (dataNode.unit) {
        return `${dataNode.name} (${dataNode.unit})`;
      }

      return `${dataNode.name}`;
    };

    this.transformDataNodeUnit = function (dataNode) {
      if (dataNode.valueType === 'boolean') {
        dataNode.unit = `${translations.boolean.true}/${translations.boolean.false}`;
      }
    };

    this.transformDataNodeValues = function (dataNodeValues) {
      return dataNodeValues.map(dataNodeValue => {
        return {x: moment(dataNodeValue.timestamp), y: dataNodeValue.value}
      });
    };

    this.hideAxisIfNoDatasetVisible = function (axis) {
      let datasets = axis.chart.data.datasets;
      datasets = datasets.filter((dataset) => dataset.yAxisID === axis.id && dataset._meta[0].hidden !== true);
      axis.options.display = datasets.length > 0;
      return axis;
    }
  }

  this.ChartFactory = new ChartFactory();
  this.MiniChartFactory = new MiniChartFactory();
  this.MixedMultiAxisChartFactory = new MixedMultiAxisChartFactory();

  return this;
}());

global['IOT'] = IOT;
